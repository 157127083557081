import { observer } from "mobx-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useReportedChannelStore,
  ReportedChannelStore,
  ReportedChannelStoreContext,
} from "./store/ReportedChannelStore";
import Filter from "./ReportedChannelFilter/reportedchannelfilter";
import { MdAdd, MdDeleteForever, MdHideSource, MdReportGmailerrorred } from "react-icons/md";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Checkbox } from "react-daisyui";

const ReportedChannelManagement = () => {
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || 1;

  const store = new ReportedChannelStore({
    isloading: true,
    editloading: false,
    pagginationcount: 1,
  });
  return (
    <ReportedChannelStoreContext.Provider value={store}>
      <Screen />
    </ReportedChannelStoreContext.Provider>
  );
};

export default ReportedChannelManagement;

const Screen = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);

  useEffect(() => {
    setActivePage(searchParamPage ?? 1);
    setIndexPage(searchParamPage ?? 1);
  }, [currentPage]);

  const [reportedid, setreportedid] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomname, setroomname] = useState("");
  const [trendingstatus, setTrendingstatus] = useState(false);
  const [isHide, setIsHide] = useState(null);
  const [selectedChannelsList, setSelectedChannelsList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [emptyinput,setemptyinput] = useState(false)

  const {
    users,
    nolist,
    statusChange,
    deleteGroup,
    removeTrendingGroup,
    activePage,
    perPage,
    Count,
    setActivePage,
    setIndexPage,
    nofilterwithpagination,
    NofilterwithPagination,
    filtertrue,
    getFilterUsers,
    filterVisibility,
    getUsers,
    indexPage,
    loaduser,
    loadTableData,
    getstatusid,
    loadReported,
    hideBulkGroup,
    deleteBulkGroup,
  } = useReportedChannelStore();

  /**
   * useEffect to get the category list with currrentPage from url params
   */
  useEffect(() => {
    if (searchParamPage) {
      const page = Number(searchParamPage ?? 1);
      setCurrentPage(page);
      setIndexPage(page);
      setSelectAll(false);
    }
  }, [searchParamPage]);

  useEffect(() => {
    setSelectedChannelsList([]);
    setSelectAll(false);
  }, [users]);

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (filtertrue) {
      getFilterUsers(pageNumber);
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
    } else {
      if (filterVisibility === true) {
        getUsers(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    }

    /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
    if (Number(pageNumber) + 1 !== 1) {
      navigate(`?page=${Number(pageNumber)}`);
      setActivePage(Number(pageNumber));
    } else {
      navigate("");
    }
    setSelectedChannelsList([]);
    setSelectAll(false);
  };

  const handleCheckboxChange = (id, name) => {
    setSelectedChannelsList((prevList = []) => {
      const idx = prevList.findIndex((p) => p.channelId == id);

      if (idx >= 0) {
        return prevList.filter((channel) => channel.channelId != id);
      } else {
        return [...prevList, { channelId: id, channelName: name }];
      }
    });
  };

  const handleCheckboxChangeAll = () => {
    if (selectAll) {
      setSelectedChannelsList([]);
    } else {
      const allGroups = users.map((data) => ({
        channelId: data.channelId._id,
        channelName: data.name,
      }));
      setSelectedChannelsList(allGroups);
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Reported Channel List
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        {!!selectedChannelsList?.length && <div>
          <label
            title="Unhide Group"
            htmlFor="unhidebulk"
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
          >
            <MdHideSource  className="mr-1" />
            {selectAll ? `Unhide All` : `Unhide`}
          </label>
          <label
            title="Hide Group"
            htmlFor="hidebulk"
            // onClick={() => {
              
            // }}
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
          >
            <MdHideSource  className="mr-1" />
            {selectAll ? `Hide All` : `Hide`}
          </label>
          <label
            title="Delete Group"
            htmlFor="deletebulk-modal"
            // onClick={() => {
            
            // }}
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
          >
            <MdDeleteForever className="mr-1" />
            {selectAll ? `Delete All` : `Delete`}
          </label>
        </div>}
      </div>
      {/* <Filter/> */}
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
              <th>
                <Checkbox
                    checked={selectAll}
                    onChange={handleCheckboxChangeAll}
                    className="checkbox"
                  />
                </th>
                <th>S.no</th>
                <th>Reported By</th>
                <th>Reported For</th>
                <th>Reported Date</th>
                <th>Reported Reason</th>
                <th>Report Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loaduser && (
                <>
                  <tr>
                    <td colSpan={7} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={7} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={7} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={7} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={7} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {users &&
                users.map((res, index) => (
                  <tr key={index}>
                      <td>
                      {" "}
                      <Checkbox
                        checked={selectedChannelsList.some(
                          (group) => group?.channelId === res?.channelId?._id
                        )}
                        onChange={() =>
                          handleCheckboxChange(res?.channelId?._id, res?.name)
                        }
                        className="checkbox"
                      />
                    </td>
                    <td> {perPage * (indexPage - 1) + (index + 1)}</td>

                    <td className=" capitalize">
                      <span className=" font-bold">{res?.userId?.name}</span>{" "}
                      <p className="capitalize text-xs">
                        +{res?.userId?.phone_number}
                      </p>
                    </td>
                    <td className=" capitalize">
                      <span className=" font-bold">{res?.channelId?.name}</span>
                    </td>
                    <td className=" capitalize">
                      <Moment format="MM/DD/YYYY">
                        {res.createdAt ?? "N/A"}
                      </Moment>
                    </td>
                    <td className=" capitalize">
                      {res?.reason?.length >= 25 ? (
                        res?.reason ? (
                          <div className="tooltip">
                            {" "}
                            {`${res?.reason}`.substring(0, 25).concat("...") ??
                              "N/A"}
                            <span className="tooltiptext bg-[#000]">
                              {" "}
                              {res?.reason}
                            </span>
                          </div>
                        ) : (
                          "N/A"
                        )
                      ) : (
                        <span className=""> {res?.reason}</span>
                      )}
                      {/* {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 20).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  } */}
                    </td>

                    {/* <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${res?.is_block === "Unblock" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setreportedid(res?.id);
                        setreportedstatus(res.is_block)
                      }}
                    >
                     {res.is_block !== "Pending" && "User Is"} {res?.is_block ==="Pending" ? "Pending" : (res?.is_block === "Unblock"? "Activated": "Inactivated" )  }
                    </label>
                  </th> */}
                    {/* <td></td> */}
                    {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedChannelmanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                    <td>
                      <div className="flex items-center space-x-4">
                        {res?.channelId?.isHide == true ? (
                          <label
                            title="Change Status"
                            htmlFor="my-modal-5"
                            className={`btn capitalize ${
                              res?.channelId?.isHide == 1 && "bg-warning"
                            } hover:bg-warning  text-[#000] border-hidden  btn-xs`}
                            onClick={(e) => {
                              setRoomId(res.channelId?._id);
                              setIsHide(res.channelId?.isHide);
                              // getNewstatus(res.isActive);
                            }}
                          >
                            {res.channelId?.isHide == true && "Unhide"}
                          </label>
                        ) : (
                          <label
                            title="Change Status"
                            htmlFor="my-modal-5"
                            className={`btn capitalize ${
                              !res.channelId?.isHide
                                ? "bg-green hover:bg-green"
                                : "bg-warning hover:bg-warning"
                            }  text-[#000] border-hidden mr-4 btn-xs`}
                            onClick={(e) => {
                              setRoomId(res.channelId?._id);
                              setIsHide(res.channelId?.isHide);
                              // getNewstatus(res.isActive);
                            }}
                          >
                            {res.channelId?.isHide == false && "Hide"}
                          </label>
                        )}
                        {/*<button className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000]  text-[#fff] border-hidden  btn-xs ml-2`}>Delete</button>*/}
                        <label
                          title="Delete Channel"
                          htmlFor="delete-modal"
                          className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000] text-[#fff] border-hidden ml-2  btn-xs`}
                          onClick={(e) => {
                            setRoomId(res.channelId?._id);
                            setroomname(res.channelId?.name);
                            // getNewstatus(res.isActive);
                          }}
                        >
                          Delete
                        </label>

                        <label
                          title="Remove Trending"
                          htmlFor="remove-trending"
                          className={`btn capitalize ${
                            res.channelId?.shouldTrending
                              ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]"
                              : "bg-warning hover:bg-warning text-[#000]"
                          }  border-hidden ml-2  btn-xs`}
                          onClick={(e) => {
                            setRoomId(res.channelId?._id);
                            setTrendingstatus(!res.channelId?.shouldTrending);
                            // getNewstatus(res.channelId?.isActive);
                          }}
                        >
                          {res.channelId?.shouldTrending
                            ? "Remove Trending"
                            : "Add Trending"}
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={7} className="my-10 text-center">
                    <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Reported Channel Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={Number(perPage)}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    statusChange(roomId, !isHide, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}

          {/* group delete model start */}
          <input type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="delete-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete this channel?
              </h3>
              <h4 className="text-[#ff0000] text-center">
                ("The owner's account will be deactivated as well.")
              </h4>
              <div className="modal-action justify-center">
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    deleteGroup(roomId, roomname, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* group delete model end */}

          {/* group delete model start */}
          <input
            type="checkbox"
            id="remove-trending"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="remove-trending"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center px-6">
              {`Are you sure you want to ${trendingstatus ? `add this channel to` : `remove this channel from`} trending?`}
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    removeTrendingGroup(roomId, trendingstatus, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>


          <input type="checkbox" id="deletebulk-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="deletebulk-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete these channels?
              </h3>
              <h4 className="text-[red] text-center">
                ("The owner's account will be deactivated as well.")
              </h4>
              <div className="modal-action justify-center">
                <label
                  htmlFor="deletebulk-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    deleteBulkGroup(selectedChannelsList, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="deletebulk-modal"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>


          <input type="checkbox" id="hidebulk" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="hidebulk"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="hidebulk"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={(e) => {
                    hideBulkGroup(selectedChannelsList, currentPage,true);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="hidebulk"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>



          <input type="checkbox" id="unhidebulk" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="unhidebulk"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="unhidebulk"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={(e) => {
                    hideBulkGroup(selectedChannelsList, currentPage,false);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="unhidebulk"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});
