import React, { useState } from "react";
import { MdAdd, MdOutlinedFlag } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import JoditEditor from 'jodit-react';
import { useFaqStore, FaqStore, FaqStoreContext } from "./Store/FaqStore";
import Filter from "./FaqFilter/faqfilter";
const FaqManagement = () => {
  const store = new FaqStore({
    isloading: true,
    editloading: false,
  });
  return (
    <FaqStoreContext.Provider value={store}>
      <Screen />
    </FaqStoreContext.Provider>
  );
};
export default FaqManagement;

const Screen = observer(() => {
  const { faq, getstatus, getfaqid, statusChange, nolist } = useFaqStore();
  const [emptyinput,setemptyinput] = useState(false)
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">FAQ Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          <Link to="./addfaq" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "><MdAdd/>Add FAQ</Link>
        </div>
        
      </div>
      <Filter emptyinput={emptyinput}/>

      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Questions</th>
                {/* <th>Content</th> */}
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {/* {loadCoupon && (
                <>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )} */}

              {faq.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>{data?.question}</td>
                  {/* <td>{data?.content}</td> */}
                  <th>
                    <label
                      title="FAQ Status"
                      htmlFor=" faqstatus"
                      className={`btn capitalize ${
                        data?.status
                          ? "bg-green hover:bg-green"
                          : "bg-warning hover:bg-warning"
                      } text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getfaqid(data?.id);
                      }}
                    >
                      {data?.status ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/faqmanagement/${data?.id}`}>
                          <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              {nolist && (
                <tr>
                  <td colSpan={4} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No FAQ Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <input type="checkbox" id=" faqstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor=" faqstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor=" faqstatus"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {setemptyinput(!emptyinput);statusChange()}}
                >
                  Yes
                </label>
                <label
                  htmlFor=" faqstatus"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
