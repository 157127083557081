import { observer } from "mobx-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import {
  useBannerStore,
  BannerStore,
  BannerStoreContext,
} from "./store/BannerStore";
import Filter from "./BannerFilter/bannerfilter";
import { MdAdd } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useAuthStore } from "../Auth/store/Auth";
import Moment from "react-moment";
// const BannerManagement = () => {
//   const [searchParams] = useSearchParams();
//   const currentPage = searchParams.get("page") || 1;

//   const store = new BannerStore({
//     isloading: true,
//     editloading: false,
//     pagginationcount: currentPage,
//   });
//   return (
//     <BannerStoreContext.Provider value={store}>
//       <Screen />
//     </BannerStoreContext.Provider>
//   );
// };

// export default BannerManagement;

const BannerManagement = observer(() => {
  const navigate = useNavigate();
  const {Token} = useAuthStore();
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [emptyinput,setemptyinput] = useState(false)
  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    setActivePage(searchParamPage ?? 1);
    setIndexPage(searchParamPage ?? 1);
  }, [currentPage]);

  const {
    banners,
    loadBanner,
    nolist,
    filterVisibility,
    activePage,
    Count,
    perPage,
    indexPage,
    filtertrue,
    nofilterwithpagination,
    NofilterwithPagination,
    setActivePage,
    getBanners,
    setIndexPage,
    statusChange,
    getstatusid,
    getFilterBanner,
    setToken
  } = useBannerStore();

  /**
   * useEffect to get the banner list with currrentPage from url params
   */
  useEffect(() => {
    if (searchParamPage) {
      const page = Number(searchParamPage ?? 1);
      setCurrentPage(page);
      setIndexPage(page);
    }
  }, [searchParamPage]);

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (filtertrue) {
      getFilterBanner(pageNumber,Token);
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
    } else {
      if (filterVisibility === true) {
        getBanners(pageNumber,Token);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    }

        /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
        if (Number(pageNumber) + 1 !== 1) {
          navigate(`?page=${Number(pageNumber)}`);
          setActivePage(Number(pageNumber));
        } else {
          navigate("");
        }

  };
   useEffect(() => {
    setToken(Token)
    getBanners(searchParams.get("page") || 1,Token);
  }, []);
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Banner Management
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        <div>
          <Link
            to="./addbanner"
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
          >
            <MdAdd />
            Add Banner
          </Link>
        </div>
      </div>
      <Filter emptyinput={emptyinput}/>
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Link</th>
                <th>Created On</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
              {loadBanner && (
                <>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {banners &&
                banners?.map((data, index) => (
                  <tr key={index}>
                    <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                    <td>
                      {data?.link && data.link.startsWith('https') ? (
                        <a className=" underline" href={data.link} target="_blank" rel="noopener noreferrer">
                          {data.link}
                        </a>
                      ) : (
                        data.link
                      )}
                    </td>
                    <td className="capitalize">
                      <Moment format="MM/DD/YYYY">{data?.createdAt}</Moment>
                    </td>
                    {/* <td className=" capitalize">Smiley, Face, Cry</td> */}
                    <th>
                      <label
                        title="Change Status"
                        htmlFor="my-modal-5"
                        className={`btn capitalize ${
                          data.status
                            ? "bg-green hover:bg-green"
                            : "bg-warning hover:bg-warning"
                        }  text-[#000] border-hidden  btn-xs`}
                        onClick={(e) => {
                          getstatusid(data?.id);
                        }}
                      >
                        {data.status ? "Active" : "Inactive"}
                      </label>
                    </th>
                    <td className=" text-base">
                      <div className="flex items-center space-x-4">
                        {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                        <button title="Edit">
                          <Link to={`/bannermanagement/${data?.id}`}>
                            <RxPencil1 />
                          </Link>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={6} className="my-10 text-center">
                    <BiCategory className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Banner Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />

          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {setemptyinput(!emptyinput);statusChange(currentPage)}}
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});

export default BannerManagement;
