import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useCategoryStore, CategoryStore, CategoryStoreContext } from "../store/CategoryStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../../Auth/store/Auth";

// const Filter = observer(() => {
//     const store = new CategoryStore({
//         isloading:true,
//       });
//     return (
//         <CategoryStoreContext.Provider value={store}>
//             <Screen />
//         </CategoryStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {
    const {Token} = useAuthStore();
    const [searchParams] = useSearchParams();
    const searchParamPage = searchParams.get("page");
    const { 
        setname,
        setstatus,
        getCategorys,
        getFilterCategory,
        setProductType,
        getProducttypeList,
        NofilterwithPagination,
        loadTableData,
        setfiltertrue,
        Producttypelist,
        name,
        setToken
      } = useCategoryStore();

      const Reset=()=>{
        setname("")
        setstatus("")
        setProductType("")
        getCategorys(searchParamPage || 1)
        setfiltertrue(false);


    }
    useEffect(() => {
        setToken(Token)
        getProducttypeList()
    },[])


    useEffect(() => {
        setname("")
        setstatus("")
        setProductType("")
    },[emptyinput])

    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {NofilterwithPagination(false);getFilterCategory(1);e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Name</label>
                            <Input
                                name="name"
                                placeholder="Name"
                                onChange={(e)=> {setname(e.target.value)}}
                                value={name}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="ProductType" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Product Type</label>
                            <select
                                id="ProductType"
                                name="ProductType"
                                defaultValue=""
                                onChange={(e)=> {setProductType(e.target.value);}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value=""  className="">All</option>
                                {Producttypelist && Producttypelist.map((res,index)=> (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        {/* <div>
                            <label htmlFor="keywords" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Keywords</label>
                            <Input
                                name="keywords"
                                placeholder="keywords"
                                id="keywords"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div> */}
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                            <select
                                id="Status"
                                name="Status"
                                defaultValue=""
                                onChange={(e)=> {setstatus(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="active">Active</option>
                                <option value="in-active">Inactive </option>
                            </select>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => { Reset()  }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})

export default Filter;
