import { observer } from "mobx-react";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import {
  useSubscriptionStore,
  SubscriptionStore,
  SubscriptionStoreContext,
} from "./store/SubscriptionStore";
import Filter from "./SubscriptionFilter/subscriptionfilter";
import { BiDollarCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import { RxEyeOpen } from "react-icons/rx";
import { Link, useParams, useSearchParams } from "react-router-dom";

const UserSubscriptionList = () => {
  const { id } = useParams();
  const store = new SubscriptionStore({
    subscription_id: id,
    isloading: true,
    pagginationcount: 1,
  });

  useEffect(() => {
    // if (id) {
      store.setSubscriptionId(id || ""); // Update the subscription ID when the component mounts
      store.getSubscriptionOrders(1); // Load the data
    // }
  }, [id]); // Make sure this effect runs when `id` changes

  return (
    <SubscriptionStoreContext.Provider value={store}>
      <Screen />
    </SubscriptionStoreContext.Provider>
  );
};

export default UserSubscriptionList;

const Screen = observer(() => {
  const { id } = useParams();
  const [emptyinput,setemptyinput] = useState(false)
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);
  useEffect(() => {
    if (searchParamPage) {
      const page = Number(searchParamPage ?? 1);
      setCurrentPage(page);
      setIndexPage(page);
    }
  }, [searchParamPage]);
  const {
    loadOrderTableData,
    setActivePage,
    setIndexPage,
    getSubscriptionOrders,
    NofilterwithPagination,
    indexPage,
    filtertrue,
    filterVisibility,
    activePage,
    perPage,
    Count,
    SubscriptionOrders,
    loadSubscriptionOrders,
    noOrderlist,
  } = useSubscriptionStore();

console.log("activePageactivePage",activePage)

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // if (!filtertrue) {
    //   getFilterSubscriptions(pageNumber);
    //   setIndexPage(pageNumber);
    //   setActivePage(pageNumber);
    // } else {
      if (filterVisibility === true) {
        getSubscriptionOrders(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    // }
  };

  //   useEffect(() => {
  //     getSubscriptionOrders(pageNumber);
  // }, []);
  //  useEffect(() => {
  //   loadTableData(1);
  // }, []);

  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
          Subscription Transaction Details
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
          >
            Back
          </Link>
        </div>
      </div>
      {/* <Filter emptyinput={emptyinput}/> */}
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Subscription ID</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Customer</th>
                <th>Plan</th>
                <th>Payment Method</th>
                <th>Payment For</th>
                <th>Payment Date</th>
                {/* <th>Payment Status</th> */}
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
              {loadSubscriptionOrders && (
                <>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {SubscriptionOrders &&
                SubscriptionOrders.map((res, index) => (
                  <tr key={index}>
                    <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                    <td className=" capitalize ">
                      #{res?.subscription?.subscription_id}
                    </td>
                    <td className=" capitalize whitespace-normal">#{res?.transaction_id}</td>
                    <td className=" capitalize">${res?.amount} </td>
                    <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.subscription?.user?.first_name}
                      </span>
                      <p className="capitalize text-xs">{res?.subscription?.user?.phone_number}</p>
                    </td>
                    <td className=" capitalize">
                      {res?.subscription?.payment_cycle ?? "N/A"}{" "}
                      {/*theme_display_name change as per api*/}
                    </td>
                    <td className=" capitalize">
                      {res?.payment_method ?? "-"}
                    </td>
                    <td className=" capitalize">{res.payment_for ?? "N/A"}</td>
                    <td className=" capitalize">
                      <Moment format="MM/DD/YYYY">
                        {res.created_at ?? "N/A"}
                      </Moment>
                    </td>
                    {/* <th>
                      <label
                        // title="Change Status"
                        // htmlFor="my-modal-5"
                        className={`btn capitalize ${
                          res?.payment_status === "RENEWED" ||
                          res?.payment_status === "Completed"
                            ? "bg-green hover:bg-green"
                            : "bg-error hover:bg-error"
                        }  text-[#000] border-hidden  btn-xs`}
                      >
                        {res?.payment_status}
                      </label>
                    </th> */}
                  </tr>
                ))}
              {noOrderlist && (
                <tr>
                  <td colSpan={9} className="my-10 text-center">
                    <BiDollarCircle className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Subscriptions Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
          {/* status change model start */}
          {/* <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});
