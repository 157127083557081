import React from 'react'
import { MdBusiness, MdMoney, MdOutlinedFlag,MdDelete , MdProductionQuantityLimits, MdSell, MdSportsHandball, MdSportsTennis, MdOutlinePersonOff } from 'react-icons/md'
import { RxBackpack, RxCalendar, RxDashboard, RxPerson } from "react-icons/rx"
import { observer } from "mobx-react";
import { HomeStore , HomeStoreContext , useHomeStore } from '../Store/HomeStore';
import { BiDollarCircle, BiPurchaseTag } from 'react-icons/bi';
import { BsBagCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const CardDashbord = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default CardDashbord;

const Screen  = observer(() => {

  function formatNumber(value) {
    if (value === null || value === undefined) {
      return "-";
    }
  
    // Remove commas from the input value
    const num = parseFloat(value.toString().replace(/,/g, ''));
  
    if (isNaN(num)) {
      return value;
    }
  
    const truncate = (num, digits) => {
      const factor = Math.pow(10, digits);
      return Math.floor(num * factor) / factor;
    };
  
    if (num >= 1e9) {
      return truncate(num / 1e9, 1) + "B";
    } else if (num >= 1e6) {
      return truncate(num / 1e6, 1) + "M";
    } else if (num >= 1e3) {
      return truncate(num / 1e3, 1) + "K";
    } else {
      return num.toString();
    }
  }

  const {dashboarddata} = useHomeStore()
  

  return (
    <div className={`grid ${ dashboarddata?.total_sell?.toString().length > 6 && "parent_card" }   xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-rows-1 gap-8`}>
      
      <Link to={"/usermanagement"}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_customers ?? "--"}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Total Customers</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <RxPerson/>
              </div>
            </div>
          </div>
        </div>
        </Link>
        <Link to={"/myearningmanagement"}>
        <div className="card shadow-md p-6  group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex  ">
            <div className="w-9/12 text-[#fff]">
              <p  className="text-[40px] font-semibold">${dashboarddata?.total_sell??" --"}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Total Earnings</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiDollarCircle/>
              </div>
            </div>
          </div>
        </div>
        </Link>

        <div className="card cursor-pointer shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <Link to={"/productmanagement"}>
      
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_products?? "--"}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Total Products</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <MdProductionQuantityLimits/>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="card cursor-pointer shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <Link to={"/ordermanagement"} >
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_orders ?? "--"}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Total Purchases</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
              <BsBagCheck />
              </div>
            </div>
          </div>
          </Link>
        </div>
        <Link to={`/usermanagement/deleteuser`}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_deleted_customers}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Deleted Users</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <MdDelete/>
              </div>
            </div>
          </div>
        </div>
        </Link>
        {/* <Link to={`/banuser`}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">
                {dashboarddata?.suspended_user_count ?? "-"}
              </p>
              <p className="text-base font-medium opacity-80 text-yellow">
                Banned Users
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <MdOutlinePersonOff />
              </div>
            </div>
          </div>
        </div>
      </Link> */}
        <Link to={"/ordermanagement"}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">
                ${dashboarddata?.total_sell ?? "-"}
              </p>

              <p className="text-base font-medium opacity-80 text-yellow">
                Total Product Sell
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiDollarCircle />
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Link to={"/subscriptionsmanagement"}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">
                ${formatNumber(dashboarddata?.total_premium_amount) ?? "-"}
              </p>

              <p className="text-base font-medium opacity-80 text-yellow">
                Total Subscription Sell
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiDollarCircle />
              </div>
            </div>
          </div>
        </div>
      </Link>
        {/* <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.recent_purchase}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Recent purchase</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiPurchaseTag/>
              </div>
            </div>
          </div>
        </div> */}

      </div>
  )
});
