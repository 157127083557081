import { observer } from "mobx-react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useBanUserStore,
  BanUserStore,
  BanUserStoreContext,
} from "./store/BanUserStore";
import Filter from "./BanUserFilter/banuserfilter";
import { MdAdd, MdReportGmailerrorred } from "react-icons/md";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  BiDetail,
  BiDollarCircle,
  BiSearch,
  BiInfoCircle,
} from "react-icons/bi";
import { useAuthStore } from "../Auth/store/Auth";
import { toast } from "react-toastify";
// const BanManagementHistory = () => {
//   const store = new BanUserStore({
//     isloading: true,
//   });
//   return (
//     <BanUserStoreContext.Provider value={store}>
//       <Screen />
//     </BanUserStoreContext.Provider>
//   );
// };

// export default BanManagementHistory;

const BanManagementHistory = observer(() => {
  const navigate = useNavigate();
  const {Token} = useAuthStore();
  const [banid, setbanid] = useState("");
  const [emptyinput,setemptyinput] = useState(false);
  const [searchParams] = useSearchParams();
  const {id} = useParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const {
    BanHistory,
    loadBan,
    nolist,
    setbanreason,
    ChangeBanStatus,
    banreason,
    setMessage,
    message,
    setReason,
    reason,
    activePage,
    perPage,
    Count,
    NofilterwithPagination,
    filtertrue,
    getBanHistory,
    setActivePage,
    setIndexPage,
    filterVisibility,
    setToken,
    indexPage
  } = useBanUserStore();

  // const handlePageChange = (pageNumber) => {
  //   NofilterwithPagination(true);
  //   const element = document.getElementById("tablescroll");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  //   // if (filtertrue) {
  //   //   getFilterUsers(pageNumber);
  //   //   setIndexPage(pageNumber);
  //   //   setActivePage(pageNumber);
  //   // } else {
  //     if (filterVisibility === true) {
  //       getBan(pageNumber);
  //       setActivePage(pageNumber);
  //       setIndexPage(pageNumber);
  //     } else {
  //       setActivePage(pageNumber);
  //       setIndexPage(pageNumber);
  //     }
  //   // }

  //   /**
  //    * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
  //    */
  //   if (Number(pageNumber) + 1 !== 1) {
  //     navigate(`?page=${Number(pageNumber)}`);
  //     setActivePage(Number(pageNumber));
  //   } else {
  //     navigate("");
  //   }
  // };

  useEffect(() => {
    setToken(Token)
    getBanHistory(id);
  }, []);
  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Attempt history for {BanHistory && BanHistory[0]?.user && BanHistory[0]?.user?.first_name+" "+(BanHistory[0]?.user?.last_name ? BanHistory[0]?.user?.last_name : "") }
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
         <div>
                  <Link
                    onClick={goBack}
                    className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
                  >
                    Back
                  </Link>
                </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                {/* <th>Name</th> */}
                <th>Attempted date</th>
                <th>Ban Removal date</th>
                <th>Suspend Type</th>
                {/* <th>Type</th> */}
                {/* <th>Status</th> */}
                <th>Reason</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadBan && (
                <>
                  {/* <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr> */}
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  {/* <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr> */}
                </>
              )}

              {BanHistory &&
                BanHistory.map((res, index) => (
                  <tr key={index}>
                     <td> {index + 1}</td>

                    {/* <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.first_name}   {res?.last_name}
                      </span>{" "}
                      <p className="capitalize text-xs">
                      {res?.email || res?.phone_number}
                      </p>
                    </td> */}
                    <td className=" capitalize">
                      {res.suspended_date ?  <Moment format="MM/DD/YYYY">
                        {res.suspended_date ?? "N/A"}
                      </Moment>
                      :
                      "N/A"}
                     
                    </td>
                    <td className=" capitalize">
                    {res.suspended_remove_date ?  <Moment format="MM/DD/YYYY">
                        {res.suspended_remove_date ?? "N/A"}
                      </Moment>
                      :
                      "N/A"}
                    </td>
                    <td className=" capitalize">
                      <p className="capitalize">
                      {res?.suspend_type}
                      </p>
                    </td>
                    {/* <td className=" capitalize">{res?.report_type ??"--"}
               { res?.report_type == "Message" && 
                <label
                title="Message"
                htmlFor="MessageDetails"
                className={`btn capitalize text-[white] border-hidden  btn-xs ml-2`}
                onClick={(e) => 
                  setMessage(res)
                }
            
              >
                                       <BiInfoCircle />

              </label>
               }
               
                </td> */}

                    {/* <th>
                      <label
                        title="Change Status"
                        htmlFor={res.violation_attempt !== 6 ? "my-modal-5" : ""}
                        className={`btn capitalize ${
                          res.violation_attempt === 6
                            ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]"
                            : "bg-warning hover:bg-warning text-[#000]"
                        }   border-hidden  btn-xs`}
                        onClick={(e) => {
                          setbanid(res?.id);
                          setbanreason("");
                        }}
                      >
                        {res?.violation_attempt === 6
                          ? "Permanently Banned"
                          : "Temporarily Banned"}
                      </label>
                    </th> */}
                    {/* <td></td> */}
                    <td className=" capitalize">

                      <label
                        title="Reason"
                        htmlFor="ReasonDetails"
                        className={`btn capitalize text-[white] border-hidden  btn-xs ml-2`}
                        onClick={(e) => {
                          setReason(res);
                          setMessage(res);
                        }}
                      >
                        <BiInfoCircle />
                      </label>
                      {/* {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 50).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  } */}
                    </td>
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Ban User Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
{/* 
          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          /> */}

            {/* reason popup */}
            <>
            <input
              type="checkbox"
              id="ReasonDetails"
              className="modal-toggle"
            />

            <div className="modal">
              <div className="modal-box relative">
                <label
                  htmlFor="ReasonDetails"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  // onClick={()=>setstatusVoidpopup(false)}
                >
                  ✕
                </label>
                {/* reason block */}
                <span className="flex justify-center font-bold text-lg text-[#2f5542]">
                  User Banned Reason
                </span>
                <div className="">
                  <p className="text-center">
                    {reason?.resion ?? "N/A"}
                  </p>
                </div>
                {/* message block */}
            
              </div>
            </div>
          </>

          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Are you sure you want to permanently ban this user?
              </h3>
              <div className="mt-3">
                <div>
              <label
                htmlFor="Reason"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Reason<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <textarea
                name="Reason"
                required
                placeholder="Reason"
                id="Reason"
                value={banreason}
                onChange={(e) => {
                    setbanreason(e.target.value);
                }}
                autoComplete="off"
                className="textarea focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              ></textarea>
            </div>
              </div>
              <div className="modal-action justify-center">
                <label
                  onClick={() => {
                    if(banreason.length > 0){
                        setemptyinput(!emptyinput);
                        ChangeBanStatus(banid);
                    }
                    else{
                        toast.error("The reason field is required.");
                    }
                  }}
                  htmlFor={banreason.length > 0 ? "my-modal-5" : ""}
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});

export default BanManagementHistory;
