import React from "react";
import { MdAdd, MdOutlinedFlag } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./CouponFilter/Filter";
import Moment from "react-moment";
import { useCouponStore, CouponStore , CouponStoreContext } from "./Store/CouponStore";
const CoupenManagement = () => {
  const store = new CouponStore({
    isloading: true,
    editloading: false,
  });
  return (
    <CouponStoreContext.Provider value={store}>
      <Screen />
    </CouponStoreContext.Provider>
  );
};
export default CoupenManagement;

const Screen = observer(() => {

  const {
coupon,
loadCoupon,
getcouponid,
statusChange,
nolist
  } = useCouponStore();
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Coupon  Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          <Link to="./addcoupen" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "><MdAdd/>Add Coupon</Link>
        </div>
      </div>
    <Filter/>

      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Coupon Name</th>
                <th>Discount Type</th>
                <th>Discount</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadCoupon && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {coupon && coupon.map((data, index) => (
                  <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>{data?.coupon_name ?? "N/A"}</td>
                  <td>{data?.discount_type ?? "N/A"}</td>
                  <td>{data?.discount ?? "N/A"}</td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.valid_from}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.valid_to}</Moment> ??
                      "N/A"}
                  </td>

                  {/* <td className=" text-center">{<Moment format="YYYY/MM/DD">{data.last_registration_date}</Moment> ?? "N/A"}</td> */}

                  <th>
                    <label
                      title="Coupon Status"
                      htmlFor="couponstatus"
                      className={`btn capitalize ${
                        data?.status
                          ? "bg-green hover:bg-green"
                          : "bg-warning hover:bg-warning"
                      } text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getcouponid(data?.id);
                      }}
                    >
                       {data?.status ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/couponmanagement/${data?.id}`}>
                          <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
                   ))}
          
              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Coupon Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <input type="checkbox" id="couponstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="couponstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status.
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="couponstatus"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label
                  htmlFor="couponstatus"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
});
