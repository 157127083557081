import React from 'react'
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson } from 'react-icons/rx'
import Moment from 'react-moment';
import userimage from "../../assets/images/user.png"
import { observer } from 'mobx-react';
import { HomeStore , HomeStoreContext , useHomeStore } from '../Store/HomeStore';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../UserManagement/store/UserStore';
import { BsBagCheck } from 'react-icons/bs';
import { useState } from 'react';
import { MdReportGmailerrorred } from 'react-icons/md';
const Table = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
  }
  export default Table

  const Screen = observer(()=>{
    const {dashboarddata,loadDashboard,statusChange,getstatusid,setreportedstatus,reportedstatus,ChangeReportedStatus} = useHomeStore()
    const [reportedid,setreportedid] = useState("")
  return (
    <>
    <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4">Recent New Users</p>
    <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Display Name</th>
                <th>Name</th>
                {/* <th>Gender</th> */}
                {/* <th>Country Code</th> */}
               
                <th>Mobile</th>
                <th>Registration Date</th>
                {/* <th>State</th> */}
                {/* <th>City</th> */}
                {/* <th>Country</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
            
            {dashboarddata && dashboarddata?.new_users?.map((data,index)=> (
              <tr key={index}>
              <td>{index+1}</td>
              <td className="font-bold capitalize">{(data?.display_name ??"-")}</td>
              <td>
                <div className="flex items-center space-x-3">
                {data.is_deleted == 1 ? 
                    
                    <>
                    <div className='flex flex-col'>
                    <div className="font-bold capitalize">{(data?.first_name??"-")}</div>
                    <div className="text-[red] capitalize"><Moment format="MM/DD/YYYY">{data.deleted_date ?? "N/A"}</Moment></div>
                    </div>
                  
                    </>
                    
                    
                    : 
                    <>
                    <div className="flex items-center space-x-3">
                  <div>
                    <div className="font-bold capitalize">{(data?.first_name??"-")+" "+(data?.last_name??"-")}</div>
                    <div className="capitalize text-xs">{data?.email}</div>
                    </div>
                    </div>

                    </>  }
                    </div>
                </td>
                {/* <td className=" capitalize">{data?.gender ?? "-"}</td> */}
                {/* <td>{data?.country_code??"-"}</td> */}
                <td>{"+"+data?.phone_number}</td>
                <td className="capitalize"><Moment format="MM/DD/YYYY">{data?.created_at}</Moment></td>
                {/* <td>{data?.state?.name ??"-"}</td> */}
                {/* <td>{data?.city?.name ??"-"}</td> */}
                {/* <td className=" capitalize">{data?.country?.name ??"-"}</td> */}
                <th>
                {data.is_deleted == 1 ?
                    <label
                    title="Change Status"
                    className={`btn capitalize ${ data.is_deleted == 1 && "bg-[red]"}  text-[#000] border-hidden  btn-xs`}
                    onClick={(e) => {
                      getstatusid(data?.id);
                      // getNewstatus(data?.isActive);
                    }}
                  >
                    {data.is_deleted == 1 && "Deleted" 
                    
                    }
                  </label>
                  :
                  <label
                  title="Change Status"
                  htmlFor="my-modal-1"
                  className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                  onClick={(e) => {
                    getstatusid(data?.id);
                    // getNewstatus(data?.isActive);
                  }}
                >
                  {   data.status ? "Active" : "Inactive"  }
                </label>
                  }
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                  { data.is_deleted == 0 &&
                         <button title="Edit">
                         <Link to={`/usermanagement/${data?.id}`}>
                             <RxPencil1 />
                         </Link>
                       </button>
                      }
                    </div>
                  </td>
              </tr>
            ))}

            {dashboarddata?.new_users?.length === 0 && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No New User Found</p>
                </td>
              </tr>
              }        
            
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-1"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-1"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-1" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
    

    {/* recent new purchase*/}
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4 mt-8">Recent New Purchases</p>
    <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Order Id</th>
                <th>TRANSACTION ID</th>
                <th>Product </th>
                {/* <th>NO. OF PRODUCTS</th> */}
                <th>Amount</th>
                <th>Customer</th>
                <th>Order Date</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">

            {loadDashboard && 
              <>
              <tr>
                <td colSpan={8} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={8} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={8} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={8} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={8} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

               {
                dashboarddata && dashboarddata?.recent_purchases?.map((res,index) => (
                    <tr key={index}>
                        <td>{index+1}</td>
                        {/* <td className=" capitalize">#1023545</td> */}
                        <td className=" capitalize">#{res?.order_number}</td>
                        <td className=" capitalize">{res?.transaction_id ? `#${res?.transaction_id}` : "---"}</td>
                        <td className=" capitalize">{ res?.category?.type?.name || res?.product?.product_type?.name || "---" }  <p className="capitalize text-xs"> { res?.category?.name || res?.product?.name || "---"} </p></td>
                        {/* <td className=" capitalize">{res?.quantity}</td> */}
                        <td className=" capitalize">${res?.final_order_amount ?? " -"}</td>
                        <td className=" capitalize"><span className=" font-bold">{res?.customer?.first_name + " " + res?.customer?.last_name}</span> <p className="capitalize text-xs">{res?.customer?.email}</p></td>
                        {/* <td className=" capitalize"></td> */}
                        <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.order_date_time ?? "N/A"}</Moment></td>
                        <th>
                            <label
                            title="Change Status"
                            // htmlFor="my-modal-2"
                            className={`btn capitalize ${res?.order_status === "Completed" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                            >
                            {res?.order_status}
                            </label>
                        </th>
                        {/* <td className=" text-base">
                            <div className="flex items-center space-x-4">
                            <button title="Edit">
                                <Link to={`/ordermanagement/${res?.id}`}>
                                    <RxPencil1 />
                                </Link>
                            </button>
                            </div>
                        </td> */}
                    </tr>
                ))
              }
             {dashboarddata?.recent_purchases?.length === 0 && 
              <tr>
                <td colSpan={8} className="my-10 text-center">
                  <BsBagCheck className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No New Purchases Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        </div>
    </div>

    {/* Recent Reported Users*/}
    <p className=" text-lg font-semibold uppercase text-moredarkgreen mb-4 mt-8">Recent Reported Users</p>
    <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Reported By</th>
                <th>Reported For</th>
                <th>Reported Date</th>
                <th>Reported Reason</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadDashboard && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                dashboarddata && dashboarddata?.recent_reported_users?.map((res,index) => (
                <tr key={index}>
                <td>{index+1}</td>

                <td className=" capitalize"><span className=" font-bold">{res?.report_user?.first_name + " " + res?.report_user?.last_name}</span> <p className="capitalize text-xs">{res?.report_user?.email}</p></td>
                <td className=" capitalize"><span className=" font-bold">{res?.reported_user?.first_name + " " + res?.reported_user?.last_name}</span> <p className="capitalize text-xs">{res?.reported_user?.email}</p></td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.date_time ?? "N/A"}</Moment></td>
                <td className=" capitalize">
                  {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 50).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  }
                  
                </td>
                
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-4"
                      className={`btn capitalize ${res.is_block === "Unblock" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setreportedid(res?.id);
                        setreportedstatus(res.is_block)
                      }}
                    >
                      {res.is_block}
                    </label>
                  </th>
                  {/* <td></td> */}
                {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedUsermanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                </tr>
             ))
            }

            {dashboarddata?.recent_reported_users?.length === 0 && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Reported User Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-4"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status.
              </h3>
              <div className='mt-3'>
                  {/* <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label> */}
                  <select
                      id="Status"
                      name="Status"
                      required
                      value={reportedstatus}
                      onChange={(e) => setreportedstatus(e.target.value)}
                      className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                      {/* <option value="" className="">All</option> */}
                      <option value="Pending">Pending</option>
                      <option value="Block">Block</option>
                      <option value="Unblock">Unblock</option>
                  </select>
              </div>
              <div className="modal-action justify-center">
              <label
                    onClick={() => {ChangeReportedStatus(reportedid)}}
                    htmlFor="my-modal-4"
                    className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  >
                  Yes
                </label>
                <label htmlFor="my-modal-4" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
    

    </div>
    </>
  )
});

